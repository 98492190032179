<template>
  <BaseModal
    v-model="isTestDeployModalActive"
    :has-modal-card="true"
    :trap-focus="true"
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-label="New application"
    aria-modal
  >
    <CardPopup
      :title="`Deployment to ${environmentDeploymentOptions.name}`"
      class="w-800"
      :show-footer="false"
      @hide="isTestDeployModalActive = false"
    >
      <template #body>
        <div class="card-content mb-3">
          <div v-if="environmentDeploymentOptions.deployed_url">
            <b-message
              type="is-success"
              size="is-small"
            >
              Your current version is <strong>{{ environmentDeploymentOptions.tag }}</strong>
            </b-message>
            <b-field class="mb-4">
              <b-input
                v-model="environmentDeploymentOptions.deployed_url"
                size="is-small"
                expanded
                icon-right-clickable
                icon-right="link"
                :disabled="true"
              />
              <p class="control">
                <b-button
                  size="is-small"
                  type="is-primary"
                  label="Go to the URL"
                  @click="goToExternalLink(environmentDeploymentOptions.deployed_url)"
                />
              </p>
            </b-field>
          </div>
          <b-message
            v-else
            type="is-danger"
            size="is-small"
          >
            There is no version deployed on the
            {{ environmentDeploymentOptions.name }}
          </b-message>

          <b-field label="You are deploying this version">
            <b-select
              v-model="environmentDeploymentOptions.version_id"
              expanded
              size="is-small"
            >
              <option
                value="-- Select a version --"
                selected
                disabled
                hidden
              >
                -- Select a version --
              </option>
              <option
                v-for="(version, index) in moduleStore.versions"
                :key="index"
                :value="version.id"
              >
                {{ version.name }}
              </option>
            </b-select>
          </b-field>

          <b-field
            label="UI Theme"
          >
            <b-select
              v-model="environmentDeploymentOptions.theme"
              expanded
              size="is-small"
            >
              <option
                v-for="(theme, index) in themes"
                :key="index"
                :value="theme.id"
              >
                {{ theme.name }}
              </option>
            </b-select>
          </b-field>

          <b-field v-if="moduleTypeId === 1">
            <b-switch v-model="environmentDeploymentOptions.password_protected">
              <span class="label">Protect module with password when deployed</span>
            </b-switch>
          </b-field>
          <b-field v-if="environmentDeploymentOptions.password_protected">
            <small>Password</small>
            <b-input
              v-model="environmentDeploymentOptions.password"
              size="is-small"
              type="password"
              expanded
              password-reveal
            />
          </b-field>

          <b-field>
            <b-switch v-model="environmentDeploymentOptions.is_analytics_active">
              <span class="label">Turn on analytics for this module</span>
            </b-switch>
          </b-field>

          <b-field label="Language (influences number format)">
            <b-select
              v-model="environmentDeploymentOptions.language"
              expanded
              size="is-small"
            >
              <option value="en">
                English
              </option>
            </b-select>
          </b-field>

          <b-field label="Only allow certain IP addresses access">
            <b-taginput
              v-model="environmentDeploymentOptions.ipAddresses"
              size="is-small"
              ellipsis
              icon="label"
              placeholder="Add an IP address."
              type="is-primary"
              aria-close-label="Delete this IP address"
            />
          </b-field>
          <b-field label="Change log">
            <Editor
              v-model="environmentDeploymentOptions.changeLog"
              :input="false"
              :formula="false"
            />
          </b-field>
          <b-message
            v-if="deploymentError"
            size="is-small"
            type="is-danger"
          >
            {{ deploymentError }}
          </b-message>
        </div>
        <b-button
          type="is-primary"
          size="is-medium"
          icon-right="rocket"
          expanded
          @click="pushDeployment()"
        >
          Deploy
        </b-button>
      </template>
    </CardPopup>
  </BaseModal>
</template>

<script >
import { computed, defineAsyncComponent, ref } from '@vue/composition-api';
import { useModuleStore } from '@/modules/builder/store/moduleStore';
import { useModuleDeployStore } from '@/modules/builder/store/moduleDeployStore';
import { useRoute } from '@/hooks/vueRouter';
import Editor from '@/modules/core/components/wysiwyg/Editor'; //-- child components --//

const BaseModal = defineAsyncComponent(() => import('@/modules/core/components/generics/base-modal/BaseModal.vue'));
const CardPopup = defineAsyncComponent(() => import('@/modules/core/components/generics/base-modal/CardPopup.vue')); //-- component props --//

const __sfc_main = {};
__sfc_main.props = {
  themes: {
    type: Array,
    default: () => []
  },
  moduleTypeId: {
    type: Number,
    default: 0
  },
  value: {
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props; //-- component events --//

  const emit = __ctx.emit; //-- use composable --//

  const moduleStore = useModuleStore();
  const moduleDeployStore = useModuleDeployStore();
  const route = useRoute(); //-- deploy environment logic --//

  const isTestDeployModalActive = computed({
    get() {
      return props.value;
    },

    set(val) {
      emit('input', val);
    }

  });
  /** @type {import('@vue/composition-api').Ref<string | null>} */

  const deploymentError = ref(null);
  const environmentDeploymentOptions = computed(() => moduleDeployStore.environmentDeploymentOptions);

  const goToExternalLink = link => {
    window.open(link, '_blank');
  };

  const pushDeployment = async () => {
    try {
      const {
        moduleId
      } = route.params;
      deploymentError.value = null;

      if (!environmentDeploymentOptions.value.changeLog || environmentDeploymentOptions.value.changeLog.replace(/(<([^>]+)>)/gi, '').trim() === '') {
        deploymentError.value = 'Change log can not be empty.';
      } else {
        await moduleDeployStore.pushDeployment(moduleId, moduleStore.moduleDetails.type);
        isTestDeployModalActive.value = false;
      }
    } catch (err) {
      console.error(err);
    }
  };

  return {
    moduleStore,
    isTestDeployModalActive,
    deploymentError,
    environmentDeploymentOptions,
    goToExternalLink,
    pushDeployment
  };
};

__sfc_main.components = Object.assign({
  BaseModal,
  CardPopup,
  Editor
}, __sfc_main.components);
export default __sfc_main;
</script>
