<template>
  <div
    v-if="validationProperties.allowed"
    class="mt-2"
  >
    <BaseLabel>Types of files allowed</BaseLabel>
    <div>
      <b-checkbox
        v-model="validationProperties.allowAllFormats"
        @input="onAllowAllFormatChange"
      >
        Allow All Formats
      </b-checkbox>
    </div>
    <div>
      <b-checkbox
        v-model="validationProperties.isCustomFormat"
        @input="onCustomFormatChange"
      >
        Custom Format
      </b-checkbox>
    </div>
    <DynamicValueSwitch
      v-if="validationProperties.isCustomFormat"
      v-model="validationProperties.customFormat"
      :is-dynamic="validationProperties.isCustomFormatDynamic"
      label="Custom Format"
      class="mt-1"
      custom-switch-classes="is-flex-direction-column-reverse"
      dynamic-switch-classes="ml-auto"
      @update-dynamic-switch="updateCustomFormatDynamic($event)"
    >
      <BaseInput
        v-model="validationProperties.customFormat"
        label="Custom Format"
        placeholder=".doc,.docx,application/msword"
      />
    </DynamicValueSwitch>
    <div
      v-else-if="!validationProperties.allowAllFormats"
      class="mt-2"
    >
      <b-select
        v-model="validationProperties.allowed"
        size="is-small"
        multiple
        expanded
        placeholder="File Format"
      >
        <option
          v-for="(format, cIndex) in formats"
          :key="cIndex"
          :value="format.value"
        >
          {{ format.label }}
        </option>
      </b-select>
    </div>
  </div>
</template>

<script >
import BaseLabel from '@/modules/core/components/generics/BaseLabel.vue';
import { useValidationProperties } from '../validationProperties';
import DynamicValueSwitch from '@/modules/core/components/DynamicValueSwitch.vue';
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
import { watch } from '@vue/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  selectedFieldType: {
    type: String,
    default: 'field'
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const {
    validationProperties
  } = useValidationProperties(props.selectedFieldType);
  const formats = [{
    label: 'Image Files',
    value: 'image/*'
  }, {
    label: 'Audio Files',
    value: 'audio/*'
  }, {
    label: 'Video Files',
    value: 'video/*'
  }, {
    label: 'Text Files',
    value: 'text/*'
  }, {
    label: 'Pdf Files',
    value: '.pdf'
  }];

  const onAllowAllFormatChange = value => {
    if (value) {
      validationProperties.value.isCustomFormat = false;
      validationProperties.value.allowed = [];
    }
  };

  const onCustomFormatChange = value => {
    if (value) {
      validationProperties.value.allowAllFormats = false;
      validationProperties.value.allowed = [];
    }
  };

  const updateCustomFormatDynamic = value => {
    validationProperties.value.isCustomFormatDynamic = value;
    validationProperties.value.customFormat = '';
  };

  watch(() => validationProperties.value, newValue => {
    if (newValue.allowAllFormats === undefined && newValue.allowed) {
      validationProperties.value.allowAllFormats = true;
      validationProperties.value.allowed = [];
    }
  }, {
    immediate: true,
    deep: true
  });
  return {
    validationProperties,
    formats,
    onAllowAllFormatChange,
    onCustomFormatChange,
    updateCustomFormatDynamic
  };
};

__sfc_main.components = Object.assign({
  BaseLabel,
  DynamicValueSwitch,
  BaseInput
}, __sfc_main.components);
export default __sfc_main;
</script>