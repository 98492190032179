<template>
  <div class="email-sender">
    <BaseInput
      v-model="emailSenderInfo.name"
      label="Node name"
    />

    <Editor
      v-model="emailSenderInfo.sender"
      :input="true"
      :formula="true"
      label="Sender"
      style="margin-bottom: 20px"
    />

    <Editor
      v-model="emailSenderInfo.destination"
      :input="true"
      :formula="true"
      label="To"
      style="margin-bottom: 20px"
    />

    <Editor
      v-model="emailSenderInfo.cc"
      :input="true"
      :formula="true"
      label="CC"
      style="margin-bottom: 20px"
    />

    <Editor
      v-model="emailSenderInfo.bcc"
      :input="true"
      :formula="true"
      label="BCC"
      style="margin-bottom: 20px"
    />

    <Editor
      v-model="emailSenderInfo.subject"
      :input="true"
      :formula="true"
      label="Subject"
      style="margin-bottom: 20px"
    />

    <Editor
      v-model="emailSenderInfo.replyTo"
      :input="true"
      :formula="true"
      label="Reply To"
      style="margin-bottom: 20px"
    />

    <Editor
      v-model="emailSenderInfo.body"
      :input="false"
      label="Content"
      style="margin-bottom: 20px"
    />

    <div
      class="mb-2"
    >
      <BaseLabel>Attachments to send</BaseLabel>
      <Draggable
        v-if="emailSenderInfo.attachments && emailSenderInfo.attachments.length"
        v-model="emailSenderInfo.attachments"
        handle=".drag-handle"
      >
        <div
          v-for="(attachmentId, index) in emailSenderInfo.attachments"
          :key="index"
          class="is-flex mt-2"
        >
          <b-icon
            icon="drag-horizontal-variant"
            class="drag-handle mr-2"
          />
          <DynamicValueSwitch
            :value="emailSenderInfo.attachmentsMeta[attachmentId] ? emailSenderInfo.attachmentsMeta[attachmentId].dynamicValue : ''"
            :is-dynamic="emailSenderInfo.attachmentsMeta[attachmentId] ? emailSenderInfo.attachmentsMeta[attachmentId].isDynamic : false"
            :label="getAttachmentName(attachmentId)"
            class="is-flex-grow-1"
            @input="updateDynamicAttachment(attachmentId, $event)"
            @update-dynamic-switch="updateDynamicAttachmentSwitch(attachmentId, $event)"
          >
            <template #default>
              <div
                v-if="emailSenderInfo.attachmentsMeta[attachmentId] ? !emailSenderInfo.attachmentsMeta[attachmentId].isDynamic : true"
                class="is-flex is-flex-grow-1 is-justify-content-space-between"
              >
                <div class="is-flex">
                  {{ getAttachmentName(attachmentId) }}
                </div>
                <b-icon
                  icon="minus-circle"
                  class="remove-icon mr-2"
                  @click.native.stop="removeSelectedAttachment(index)"
                />
              </div>
            </template>
          </DynamicValueSwitch>
        </div>
      </Draggable>
      <div v-else>
        None
      </div>
    </div>

    <div
      v-if="attachments && unselectedAttachments && unselectedAttachments.length"
      class="mb-2"
    >
      <BaseLabel>Files</BaseLabel>
      <div
        v-for="(attachment, index) in unselectedAttachments"
        :key="index"
        class="mt-2"
      >
        <div class="is-flex is-justify-content-space-between">
          <div>
            {{ getAttachmentName(attachment.nodeId) }}
          </div>
          <b-icon
            icon="plus-circle"
            class="add-icon mr-5"
            type="is-primary"
            @click.native.stop="addAttachment(attachment.nodeId)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script >
// libs
import { computed, set, watch } from '@vue/composition-api';
import Draggable from 'vuedraggable'; // components

import Editor from '@/modules/core/components/wysiwyg/Editor';
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
import BaseLabel from '@/modules/core/components/generics/BaseLabel.vue';
import DynamicValueSwitch from '@/modules/core/components/DynamicValueSwitch.vue'; // stores

import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
import { useModuleStore } from '@/modules/builder/store/moduleStore'; // composables

import { refResetter } from '@/hooks/utils';
import { useModuleSidebar } from '@/modules/builder/components/module-sidebar/moduleSidebar';
import { useRoute } from '@/hooks/vueRouter';
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const moduleStore = useModuleStore();
  const moduleGraphStore = useModuleGraphStore();
  const route = useRoute();
  const [emailSenderInfo, resetEmailSenderInfo] = refResetter({
    name: 'EmailSender',
    application_id: null,
    module_id: null,
    sender: '',
    destination: '',
    bcc: '',
    cc: '',
    subject: '',
    body: '',
    attachments: [],
    attachmentsMeta: {},
    replyTo: ''
  });
  const attachments = computed(() => moduleStore.moduleDocuments);
  const unselectedAttachments = computed(() => attachments.value.filter(attachment => !emailSenderInfo.value.attachments.includes(attachment.nodeId)));
  watch(() => moduleGraphStore.selectedNodeId, () => {
    const {
      appId,
      moduleId
    } = route.params;
    moduleStore.fetchModuleDocuments(appId, moduleId);
  }, {
    immediate: true
  });

  const stripContent = string => string.replace(/<\/?[^>]+>/ig, '');

  const getAttachmentName = attachmentId => {
    const attachment = attachments.value?.find(attachment => attachment.nodeId === attachmentId);

    if (attachment) {
      return stripContent(attachment.info.name + ' > ' + attachment.info.fileName + '.' + attachment.info.extension.toLowerCase());
    }

    return '';
  };

  const addAttachment = attachmentId => {
    emailSenderInfo.value.attachments.push(attachmentId);
  };

  const removeSelectedAttachment = index => {
    emailSenderInfo.value.attachments.splice(index, 1);
  };

  const updateDynamicAttachment = (attachmentId, value) => {
    if (attachmentId in (emailSenderInfo.value?.attachmentsMeta || {})) {
      emailSenderInfo.value.attachmentsMeta[attachmentId].dynamicValue = value;
    } else {
      set(emailSenderInfo.value.attachmentsMeta, attachmentId, {
        isDynamic: true,
        dynamicValue: ''
      });
    }
  };

  const updateDynamicAttachmentSwitch = (attachmentId, isDynamic) => {
    if (isDynamic) {
      set(emailSenderInfo.value.attachmentsMeta, attachmentId, {
        isDynamic,
        dynamicValue: ''
      });
    } else {
      if (attachmentId in emailSenderInfo.value.attachmentsMeta) {
        delete emailSenderInfo.value.attachmentsMeta[attachmentId];
      }
    }
  }; //-- load info logic --//


  const {
    isSidebarInfoLoaded
  } = useModuleSidebar(emailSenderInfo, resetEmailSenderInfo, 'Send Email');
  watch(() => isSidebarInfoLoaded.value, () => {
    if (isSidebarInfoLoaded.value) {
      // add new properties if they are missing in email info
      const newProps = [{
        key: 'attachmentsMeta',
        value: {}
      }];
      newProps.forEach(newProp => {
        if (!(newProp.key in emailSenderInfo.value)) {
          set(emailSenderInfo.value, newProp.key, newProp.value);
        }
      });
    }
  });
  return {
    emailSenderInfo,
    attachments,
    unselectedAttachments,
    getAttachmentName,
    addAttachment,
    removeSelectedAttachment,
    updateDynamicAttachment,
    updateDynamicAttachmentSwitch
  };
};

__sfc_main.components = Object.assign({
  BaseInput,
  Editor,
  BaseLabel,
  Draggable,
  DynamicValueSwitch
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';

.email-sender {
  .drag-handle {
    border-radius: 0.125rem;
    cursor: pointer;
    &:hover {
      background-color: $grey;
    }
  }
  .remove-icon, .add-icon {
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }
  .remove-icon {
    color: rgba(black, 0.3);
  }
}
</style>