<template>
  <div>
    <BaseHeading
      tag="h6"
      class="mt-3 mb-1 has-text-weight-bold"
    >
      {{ props.heading }}
    </BaseHeading>
    <div>
      <InvalidValueInput
        v-if="validationProperties.message !== undefined"
        :selected-field-type="selectedFieldType"
      />
      <RequiredFieldInput
        v-if="validationProperties.isRequired !== undefined"
        :selected-field-type="selectedFieldType"
      />
      <RegexInput 
        v-if="selectedFieldType === 'iban' || validationProperties.hasRegex !== undefined"
        :selected-field-type="selectedFieldType"
      />
      <DateRestrictionInput 
        v-if="validationProperties.hasRestriction !== undefined"
        :selected-field-type="selectedFieldType"
      />
      <FileUploadProperties v-if="selectedFieldType === 'file-upload'" />
      <RangeErrorInput
        v-if="selectedField.type === 'number-input'"
        :selected-field-type="selectedFieldType"
      />
    </div>
  </div>
</template>

<script >
// components
import { RequiredFieldInput, RegexInput, DateRestrictionInput } from './components/validationComponentsIndex';
import BaseLabel from '@/modules/core/components/generics/BaseLabel.vue';
import BaseHeading from '@/modules/core/components/generics/BaseHeading.vue';
import RangeErrorInput from '@/modules/builder/components/form-builder/properties/field-properties/validation-properties/components/RangeErrorInput.vue'; // composables

import { useValidationProperties } from './validationProperties';
import { useFormBuilder } from '@/modules/builder/components/form-builder/formBuilder';
import InvalidValueInput from '@/modules/builder/components/form-builder/properties/field-properties/validation-properties/components/InvalidValueInput.vue';
import FileUploadProperties from './components/FileUploadProperties.vue';
const __sfc_main = {};
__sfc_main.props = {
  selectedFieldType: {
    type: String,
    default: 'field'
  },
  heading: {
    type: String,
    default: 'Validation:'
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const {
    validationProperties
  } = useValidationProperties(props.selectedFieldType);
  const {
    selectedField
  } = useFormBuilder();
  return {
    props,
    validationProperties,
    selectedField
  };
};

__sfc_main.components = Object.assign({
  BaseHeading,
  InvalidValueInput,
  RequiredFieldInput,
  RegexInput,
  DateRestrictionInput,
  FileUploadProperties,
  RangeErrorInput
}, __sfc_main.components);
export default __sfc_main;
</script>
