import { computed, ref } from '@vue/composition-api';
import { useModuleStore } from '@/modules/builder/store/moduleStore';
import { refResetter } from '@/hooks/utils';

const selectedFieldIndices = ref({
    sectionIndex: null,
    columnIndex: null,
    fieldIndex: null,
    type: null
});

const [selected, resetFormBuilderInfo] = refResetter({
    name: 'Form',
    custom_url_name: null,
    layout: null,
    application_id: null,
    module_id: null,
    sections: [
        {
            columns: '1',
            show: false,
            rules: [],
            condition: {
                logicalOperator: 'all',
                children: [],
            },
            fields: [
                [{type: 'hidden'}]
            ]
        }
    ],
    additionalInfo: {},
});

export const useFormBuilder = () => {
    const moduleStore = useModuleStore();
    
    //-- rules --//
    const rules = computed(() => {
        return moduleStore.variables.map(variable => ({
            type: 'text',
            id: variable.reference,
            label: variable.name,
            operators: ['equals', 'does not equal', 'less than', 'more than', 'less than or equal to', 'more than or equal to', 'empty', 'not empty', 'contains', 'does not contain', 'begins with', 'ends with', 'is part of','is not part of']
        }));
    });

    //-- selected --//
    const selectedSection = computed(() => {
        const { sectionIndex } = selectedFieldIndices.value;
        if (sectionIndex !== null) {
            return selected.value.sections[sectionIndex];
        }
        return null;
    });
    const selectedFields = computed(() => {
        const { columnIndex } = selectedFieldIndices.value;
        if (columnIndex !== null && selectedSection.value) {
            return selectedSection.value.fields[columnIndex];
        }
        return null;
    });
    const selectedField = computed(() => {
        const { fieldIndex } = selectedFieldIndices.value;
        if (fieldIndex !== null && selectedFields.value?.length) {
            return selectedFields.value[fieldIndex];
        }
        return null;
    });
    
    return {
        rules,
        selected,
        resetFormBuilderInfo,
        selectedFieldIndices,
        selectedSection,
        selectedFields,
        selectedField
    };
};
