<template>
  <div>
    <small>Node name</small>
    <b-input
      v-model="redirectInfo.name"
      size="is-small"
      class="mb-2"
    />
    <small>URL to redirect</small>
    <editor
      v-model="redirectInfo.url"
      :input="true"
      :formula="true"
    />
    <DynamicValueSwitch
      v-model="redirectInfo.openInNewTab"
      :is-dynamic="redirectInfo.isOpenInNewTabDynamic"
      label="Open in new tab"
      class="mt-3"
      @update-dynamic-switch="onSwitchUpdate"
    />
  </div>
</template>

<script >
import Editor from '@/modules/core/components/wysiwyg/Editor';
import { refResetter } from '@/hooks/utils';
import { useModuleSidebar } from '@/modules/builder/components/module-sidebar/moduleSidebar';
import DynamicValueSwitch from '@/modules/core/components/DynamicValueSwitch.vue';
import { set } from '@vue/composition-api';
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const [redirectInfo, resetRedirectInfo] = refResetter({
    name: 'Redirect',
    application_id: null,
    module_id: null,
    url: '',
    openInNewTab: false,
    isOpenInNewTabDynamic: false
  });

  const onSwitchUpdate = value => {
    set(redirectInfo.value, 'isOpenInNewTabDynamic', value);
  }; //-- load info logic --//


  useModuleSidebar(redirectInfo, resetRedirectInfo, 'Redirect');
  return {
    redirectInfo,
    onSwitchUpdate
  };
};

__sfc_main.components = Object.assign({
  Editor,
  DynamicValueSwitch
}, __sfc_main.components);
export default __sfc_main;
</script>
