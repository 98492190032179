<template>
  <BaseInput
    size="is-small"
    :disabled="field.properties.basic.isDisabled"
    :placeholder="field.properties.basic.hasPlaceholder ? field.properties.basic.placeholder : ''"
    :value="field.properties.basic.hasDefaultValue ? field.properties.basic.defaultValue : ''"
    :maxlength="field.properties.basic.hasLimit ? field.properties.basic.limit : ''"
    :mask="ibanMask"
  />
</template>

<script >
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const ibanMask = {
    blocks: [4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
    uppercase: true
  };
  return {
    ibanMask
  };
};

__sfc_main.components = Object.assign({
  BaseInput
}, __sfc_main.components);
export default __sfc_main;
</script>
