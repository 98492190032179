<template>
  <div>
    <b-datetimepicker
      v-bind="dateRange"
      ref="datetimepicker"
      v-model="date"
      size="is-small"
      expanded
      editable
      locale="de-DE"
      :disabled="field.properties.basic.isDisabled"
      :placeholder="
        field.properties.basic.hasPlaceholder
          ? formatPlaceholder(field.properties.basic.placeholder)
          : ''
      "
      :datetime-formatter="dateTimeFormatter"
      :datetime-parser="dateTimeParser"
      :timepicker="{
        hourFormat,
        disabled: !field.properties.basic.hasTimePicker,
      }"
      horizontal-time-picker
    >
      <template #left>
        <b-button
          label="Jetzt"
          type="is-primary"
          icon-left="clock"
          @click="selectCurrentDateTime"
        />
      </template>
      <template #right>
        <div class="is-flex is-flex-direction-column is-align-items-center">
          <b-button
            label="Löschen"
            type="is-danger"
            icon-left="close"
            outlined
            @click="clearDateTime"
          />
          <b-button
            v-if="field.properties.basic.hasTimePicker"
            label="Ok"
            type="is-primary"
            icon-left="check"
            class="mt-5"
            @click="datetimepicker.toggle()"
          />
        </div>
      </template>
    </b-datetimepicker>
  </div>
</template>

<script >
// libs
import { computed, ref, watch, onMounted } from '@vue/composition-api';
import Inputmask from 'inputmask';
import moment from 'moment';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const datetimepicker = ref(null);
  const datetime = ref(null);
  const datePickerFormat = props.field.properties.basic.format || 'DD.MM.YYYY';
  const isCurrentDateTime = ref(false);
  const date = computed({
    get() {
      return datetime.value;
    },

    set(value) {
      if (value && !props.field.properties.basic.hasTimePicker || isCurrentDateTime.value) {
        datetimepicker.value.toggle();
      }

      isCurrentDateTime.value = false;
      datetime.value = value;
    }

  });
  const hourFormat = computed(() => {
    const fullFormat = props.field.properties.basic.format;

    if (fullFormat?.indexOf('h') !== -1) {
      return '12';
    } else {
      return '24';
    }
  });
  const dateRange = computed(() => {
    const range = {};
    if (!props.field.properties.validation.hasRestriction) return range;
    const restrictedFromDate = new Date(props.field.properties.validation.restrictedFrom);
    const restrictedToDate = new Date(props.field.properties.validation.restrictedTo);

    if (!isNaN(restrictedToDate)) {
      range.maxDatetime = restrictedToDate;
      range.maxDatetime.setHours(0, 0, 0, 0);
    }

    if (!isNaN(restrictedFromDate)) {
      range.minDatetime = restrictedFromDate;
      range.minDatetime.setHours(0, 0, 0, 0);
    }

    return range;
  });

  const dateTimeFormatter = date => {
    let dateFormat = props.field.properties.basic.format;
    return moment(date).format(dateFormat || 'DD.MM.YYYY');
  };

  const formatPlaceholder = dateString => {
    const date = moment(dateString);

    if (date.isValid()) {
      return date.format(datePickerFormat);
    } else {
      return dateString;
    }
  };

  const updateMask = () => {
    if (props.field.properties.basic?.format) {
      const dateTimeFormat = props.field.properties.basic.format.toUpperCase();
      let maskValue = '';

      for (let char of dateTimeFormat) {
        switch (char) {
          case 'M':
          case 'D':
          case 'Y':
          case 'H':
          case 'S':
            maskValue += '9';
            break;

          case 'A':
            maskValue += 'AM';
            break;

          case 'P':
          default:
            maskValue += char;
            break;
        }
      }

      const inputMaskInstance = new Inputmask({
        mask: maskValue
      });
      const inputElement = datetimepicker.value?.$el.querySelector('input');

      if (inputElement) {
        inputMaskInstance.mask(inputElement);
      }
    }
  };

  const dateTimeParser = dateString => {
    let momentObj = moment(dateString, datePickerFormat);

    if (!props.field.properties.basic.hasTimePicker) {
      momentObj = momentObj?.startOf('day');
    }

    return momentObj.toDate();
  };

  const selectCurrentDateTime = () => {
    isCurrentDateTime.value = true;
    let currentDate = moment();

    if (!props.field.properties.basic.hasTimePicker) {
      currentDate = currentDate.startOf('day');
    }

    date.value = currentDate.toDate();
  };

  const clearDateTime = () => {
    date.value = null;
  };

  const toggleDatePicker = () => {
    const timepickerElement = datetimepicker.value?.$el.querySelector('.timepicker');

    if (timepickerElement) {
      if (props.field.properties.basic.hasTimePicker || props.field.properties.basic.isTimePickerToggleDynamic) {
        timepickerElement.style.display = 'block';
      } else {
        timepickerElement.style.display = 'none';
      }
    }
  };

  onMounted(() => {
    updateMask();
    toggleDatePicker();
  });
  watch(() => props.field.properties.basic.format, () => {
    updateMask();
  }, {
    immediate: true,
    deep: true
  });
  watch(() => props.field.properties.basic.hasTimePicker, () => {
    toggleDatePicker();
  }, {
    immediate: true,
    deep: true
  });
  return {
    datetimepicker,
    date,
    hourFormat,
    dateRange,
    dateTimeFormatter,
    formatPlaceholder,
    dateTimeParser,
    selectCurrentDateTime,
    clearDateTime
  };
};

export default __sfc_main;
</script>
<style lang="scss">
.datepicker-footer{
    .level{
        display: flex;
        flex-direction: column;
        .level-item {
            margin-right: 0 !important;
        }
    }
    button {
        width: fit-content;
    }
}
</style>
