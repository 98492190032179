<template>
  <div>
    <BaseInput
      v-model="fileMoverInfo.name"
      label="Node name"
    />
    
    <BaseInput
      v-model="fileMoverInfo.host"
      label="Host"
    />
  
    <BaseInput
      v-model="fileMoverInfo.user"
      label="User"
    />
  
    <BaseInput
      v-model="fileMoverInfo.password"
      type="password"
      label="Password"
    />
  
    <BaseInput
      v-model="fileMoverInfo.port"
      type="number"
      label="Port"
    />
  
    <BaseInput
      v-if="!fileMoverInfo.moveAllFiles"
      v-model="fileMoverInfo.fileName"
      label="File Name"
    />

    <BaseInput
      v-model="fileMoverInfo.sourcePath"
      label="Source folder structure"
    />

    <BaseInput
      v-model="fileMoverInfo.destinationPath"
      label="Destination folder structure"
    />
  
    <b-switch
      v-model="fileMoverInfo.moveAllFiles"
      class="mb-4"
    >
      Move all files in the folder?
    </b-switch>
  
    <b-button
      expanded
      type="is-primary"
      class="mb-3"
      @click="testConnection"
    >
      Test the connection
    </b-button>
    <b-message
      v-if="connectionOK"
      size="is-small"
      type="is-success"
    >
      Connection successful
    </b-message>
    <b-message
      v-if="!connectionOK && connectionTestSent"
      size="is-small"
      type="is-danger"
    >
      There is a problem with a connection.
    </b-message>
  </div>
</template>
  
<script >
// libs
import { ref } from '@vue/composition-api'; // components

import BaseInput from '@/modules/core/components/generics/BaseInput.vue'; // services

import { parseModuleService } from '@/services/interpreter-service/interpreterModuleRequests'; // stores

import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
import { useModuleStore } from '@/modules/builder/store/moduleStore'; // composables

import { useRoute } from '@/hooks/vueRouter';
import { refResetter } from '@/hooks/utils';
import { useModuleSidebar } from '@/modules/builder/components/module-sidebar/moduleSidebar';
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const route = useRoute();
  const moduleGraphStore = useModuleGraphStore();
  const moduleStore = useModuleStore();
  const [fileMoverInfo, resetFileMoverInfo] = refResetter({
    name: 'FileMover',
    application_id: null,
    module_id: null,
    host: '',
    user: '',
    password: '',
    port: '22',
    sourcePath: '/',
    destinationPath: '/',
    moveAllFiles: true,
    fileName: ''
  }); //-- load info logic --//

  useModuleSidebar(fileMoverInfo, resetFileMoverInfo, 'Move a file'); //-- test connection logic --//

  const connectionOK = ref(false);
  const connectionTestSent = ref(false);

  const testConnection = async () => {
    try {
      const {
        appId,
        moduleId
      } = route.params;
      await parseModuleService(moduleId, { ...fileMoverInfo.value,
        application_id: appId,
        node_id: moduleGraphStore.selectedNodeId,
        node_name: fileMoverInfo.value.name
      });
      connectionOK.value = true;
      await moduleStore.fetchVariables(appId, moduleId);
    } catch (err) {
      console.error(err);
      connectionTestSent.value = true;
    }
  };

  return {
    fileMoverInfo,
    connectionOK,
    connectionTestSent,
    testConnection
  };
};

__sfc_main.components = Object.assign({
  BaseInput
}, __sfc_main.components);
export default __sfc_main;
</script>
  