import * as elementFactories from '@/modules/builder/components/form-builder/form-elements/elementFactories';

export const formElementList = [
    {
        label: 'Section',
        icon: 'card-bulleted-outline',
        type: 'section',
        category: 'layout',
        factory: elementFactories.createSection
    },
    {
        label: 'Text input',
        icon: 'form-textbox',
        type: 'text-input',
        category: 'basic elements',
        factory: elementFactories.createTextInput
    },
    {
        label: 'Email input',
        icon: 'email',
        type: 'email-input',
        category: 'basic elements',
        factory: elementFactories.createEmailInput
    },
    {
        label: 'Text area',
        icon: 'form-textarea',
        type: 'textarea',
        category: 'basic elements',
        factory: elementFactories.createTextArea
    },
    {
        label: 'Number',
        icon: 'counter',
        type: 'number-input',
        category: 'basic elements',
        factory: elementFactories.createNumberInput
    },
    {
        label: 'Radio box',
        icon: 'radiobox-marked',
        type: 'radio-box',
        category: 'basic elements',
        factory: elementFactories.createRadioBox
    },
    {
        label: 'Checkbox',
        icon: 'checkbox-marked',
        category: 'basic elements',
        type: 'checkbox',
        factory: elementFactories.createCheckBox
    },
    {
        label: 'Dropdown',
        icon: 'form-select',
        type: 'dropdown',
        category: 'basic elements',
        factory: elementFactories.createDropdown
    },
    {
        label: 'Date',
        icon: 'calendar-range',
        type: 'date',
        category: 'basic elements',
        factory: elementFactories.createDateInput
    },
    {
        label: 'Time',
        icon: 'clock-time-twelve-outline',
        type: 'time',
        category: 'basic elements',
        factory: elementFactories.createTimeInput
    },
    {
        label: 'File upload',
        icon: 'file-upload',
        type: 'file-upload',
        category: 'basic elements',
        factory: elementFactories.createFileUploadInput
    },
    {
        label: 'Password',
        icon: 'form-textbox-password',
        type: 'password',
        category: 'basic elements',
        factory: elementFactories.createPasswordInput
    },
    {
        label: 'Text and images',
        icon: 'format-text',
        type: 'text',
        category: 'basic elements',
        factory: elementFactories.createTextField
    },
    {
        label: 'IFrame',
        icon: 'application-brackets-outline',
        type: 'iframe',
        category: 'basic elements',
        factory: elementFactories.createIFrame
    },
    {
        label: 'Data Table',
        icon: 'table',
        type: 'data-table',
        category: 'basic elements',
        factory: elementFactories.createDataTable
    },
    {
        label: 'HTML Editor',
        icon: 'code-tags',
        type: 'html-editor',
        category: 'basic elements',
        factory: elementFactories.createHTMLEditor
    },
    {
        label: 'Tile',
        icon: 'view-grid',
        type: 'tile',
        category: 'basic elements',
        factory: elementFactories.createTile
    },
    {
        label: 'Terms and conditions',
        icon: 'draw',
        type: 'terms-conditions',
        category: 'advance elements',
        factory: elementFactories.createTermsAndConditionField
    },
    {
        label: 'Address',
        icon: 'home',
        type: 'address',
        category: 'advance elements',
        factory: elementFactories.createAddressInput
    },
    {
        label: 'IBAN',
        icon: 'bank',
        type: 'iban',
        category: 'advance elements',
        factory: elementFactories.createIbanField
    },
    {
        label: 'E-Signature',
        icon: 'pen',
        type: 'signature',
        category: 'advance elements',
        factory: elementFactories.createESignatureField
    },
    {
        label: 'Document',
        icon: 'file',
        type: 'document',
        category: 'advance elements',
        factory: elementFactories.createDocument
    },
    {
        label: 'Tariff comparison',
        icon: 'compare',
        type: 'tariff-comparison',
        category: 'advance elements',
        factory: elementFactories.createTariffComparisionInput
    },
    {
        label: 'Payment Gateway',
        icon: 'credit-card-plus-outline',
        type: 'payment-gateway',
        category: 'advance elements',
        factory: elementFactories.createPaymentGateway
    },
    {
        label: 'Matrix',
        icon: 'table',
        type: 'grid',
        category: 'advance elements',
        factory: elementFactories.createGrid
    },
    {
        label: 'Advance button',
        icon: 'arrow-right-box',
        type: 'next-btn',
        category: 'advance elements',
        factory: elementFactories.createNextButton
    },
    {
        label: 'Return button',
        icon: 'arrow-left-box',
        type: 'back-btn',
        category: 'advance elements',
        factory: elementFactories.createBackButton
    }
];
