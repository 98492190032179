<template>
  <b-clockpicker 
    ref="timepicker"
    v-model="time"
    locale="de-DE"
    :hour-format="hourFormat"
    editable
  >
    <div class="is-flex is-justify-content-space-between">
      <b-button
        label="Jetzt"
        type="is-primary"
        icon-left="clock"
        class="pl-3 pr-3"
        @click="selectCurrentTime"
      />
      <b-button
        label="Löschen"
        type="is-danger"
        icon-left="close"
        outlined
        class="pl-3 pr-3"
        @click="time = null"
      />
      <b-button
        label="Ok"
        type="is-primary"
        icon-left="check"
        class="pl-3 pr-3"
        @click="timepicker.toggle()"
      />
    </div>
  </b-clockpicker>
</template>

<script >
// libs
import { ref, computed, watch, onMounted } from '@vue/composition-api';
import Inputmask from 'inputmask';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const time = ref();
  const timepicker = ref(null);
  const hourFormat = computed(() => {
    if (props.field.properties.basic.is24HourFormat) {
      return '24';
    } else {
      return '12';
    }
  });

  const updateMask = () => {
    const inputMaskInstance = new Inputmask({
      mask: hourFormat.value === '12' ? '99:99 aa' : '99:99'
    });
    const inputElement = timepicker.value?.$el.querySelector('input');

    if (inputElement) {
      inputMaskInstance.mask(inputElement);
    }
  };

  const selectCurrentTime = () => {
    time.value = new Date();
    timepicker.value?.toggle();
  };

  onMounted(() => {
    updateMask();
  });
  watch(() => hourFormat.value, newVal => {
    if (newVal) {
      updateMask();
    }
  }, {
    immediate: true
  });
  return {
    time,
    timepicker,
    hourFormat,
    selectCurrentTime
  };
};

export default __sfc_main;
</script>
