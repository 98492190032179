<template>
  <div>
    <BaseInput
      v-model="localDatabaseInfo.name"
      label="Node name"
    />

    <BaseSelect
      :value="localDatabaseInfo.database ? localDatabaseInfo.database.id : ''"
      label="Database"
      @input="updateSelectedDatabase"
    >
      <option
        v-for="database in options.databases"
        :key="database.id"
        :value="database.id"
      >
        {{ database.name }}
      </option>
    </BaseSelect>

    <div v-if="localDatabaseInfo.database && localDatabaseInfo.action !== 'Join'">
      <BaseSelect
        :value="localDatabaseInfo.table ? localDatabaseInfo.table.TABLE_NAME : ''"
        label="Tables"
        @input="updateSelectedTable"
      >
        <option
          v-for="(table, index) in options.tables"
          :key="index"
          :value="table.TABLE_NAME"
        >
          {{ table.TABLE_LABEL }}
        </option>
      </BaseSelect>
    </div>

    <div v-if="localDatabaseInfo.table">
      <BaseSelect
        v-model="localDatabaseInfo.action"
        label="Action"
        @input="handleActionUpdate"
      >
        <option
          v-for="(action, index) in options.actions"
          :key="index"
        >
          {{ action }}
        </option>
      </BaseSelect>
    </div>

    <div
      v-if="localDatabaseInfo.action === 'Update on duplicate insert' && localDatabaseInfo.table"
    >
      <BaseSelect
        v-model="localDatabaseInfo.duplicateVerifiers"
        label="Check duplicate records via:"
        native-size="5"
        multiple
      >
        <option
          v-for="(column, index) in localDatabaseInfo.columns"
          :key="index"
          :value="column.id"
        >
          {{ column.label }}
        </option>
      </BaseSelect>
    </div>
    <div v-if="(localDatabaseInfo.action === 'Write' || localDatabaseInfo.action === 'Update on duplicate insert') && localDatabaseInfo.table">
      <BaseLabel variant="bold">
        Columns
      </BaseLabel>
      <div
        v-for="(column, index) in localDatabaseInfo.columns"
        :key="index"
      >
        <div class="is-flex is-justify-content-end">
          <b-switch v-model="column.isHtml">
            HTMl
          </b-switch>
        </div>
        <Editor
          v-model="column.value"
          :input="true"
          :formula="true"
          :label="column.label"
          :type="column.type"
          style="margin-bottom:20px"
        />
      </div>
    </div>

    <div v-if="localDatabaseInfo.action === 'Update' && localDatabaseInfo.table">
      <BaseLabel variant="bold">
        Columns
      </BaseLabel>
      <div
        v-for="(column, index) in localDatabaseInfo.columns"
        :key="index"
      >
        <div class="is-flex is-justify-content-end">
          <b-switch v-model="column.isHtml">
            HTMl
          </b-switch>
        </div>
        <Editor
          v-model="column.value"
          :input="true"
          :formula="true"
          :label="column.label"
          :type="column.type"
          style="margin-bottom:20px"
        />
      </div>
    </div>

    <div
      v-if="localDatabaseInfo.action === 'Join'"
    >
      <JoinBuilder 
        @mapping-update="handleVariables"
      />
    </div>
    <div
      v-if="['Read','Join'].includes(localDatabaseInfo.action) && localDatabaseInfo.table"
      class="mt-3"
    >
      <b-switch 
        v-model="localDatabaseInfo.shouldPaginateResult"
      >
        Paginate Result
      </b-switch>
      <BaseInput
        v-if="localDatabaseInfo.shouldPaginateResult"
        v-model.number="localDatabaseInfo.limit"
        class="mt-1"
        type="number"
        label="Limit"
      />
      <div class="mt-1">
        <b-switch
          v-model="localDatabaseInfo.shouldSortResult"
        >
          Sort Result
        </b-switch>
      </div>
      <template v-if="localDatabaseInfo.shouldSortResult">
        <BaseSelect
          v-model="localDatabaseInfo.sort_by"
          label="Sort by"
          placeholder="Select a column"
          class="mt-1"
        >
          <option
            v-for="(column, index) in columnList"
            :key="index"
            :value="column.column_name"
          >
            {{ column.column_label }}
          </option>
        </BaseSelect>
        <template v-if="localDatabaseInfo.sort_by">
          <BaseSelect
            v-model="localDatabaseInfo.sort_direction"
            label="Sort direction"
            class="mt-1"
          >
            <option value="asc">
              Ascending
            </option>
            <option value="desc">
              Descending
            </option>
          </BaseSelect>
        </template>
      </template>
    </div>
    <div v-if="['Read', 'Update', 'Update on duplicate insert', 'Join'].includes(localDatabaseInfo.action)">
      <ConditionBuilder />
    </div>
  </div>
</template>

<script >
// libs
import { ref, nextTick, watch } from '@vue/composition-api'; // components

import Editor from '@/modules/core/components/wysiwyg/Editor';
import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue';
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
import BaseLabel from '@/modules/core/components/generics/BaseLabel.vue';
import JoinBuilder from './JoinBuilder.vue'; // services

import { fetchDatabasesService } from '@/services/database-service/databaseRequests';
import { fetchTablesService } from '@/services/database-service/tableRequests';
import { getColumnsService } from '@/services/database-service/columnRequests'; // stores

import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
import { useModuleStore } from '@/modules/builder/store/moduleStore'; // composables

import { useRoute } from '@/hooks/vueRouter';
import { useModuleSidebar } from '@/modules/builder/components/module-sidebar/moduleSidebar';
import { useLocalDatabase } from './localDatabase';
import ConditionBuilder from './ConditionBuilder.vue';
import lodash from 'lodash';
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const route = useRoute();
  const moduleGraphStore = useModuleGraphStore();
  const moduleStore = useModuleStore();
  const {
    localDatabaseInfo,
    options,
    resetLocalDatabaseInfo,
    selectedTableColumns,
    joinedTableColumns,
    isDefaultDateTypeColumn
  } = useLocalDatabase();
  const {
    isSidebarInfoLoaded
  } = useModuleSidebar(localDatabaseInfo, resetLocalDatabaseInfo, 'Local Database Actions');
  const columnList = ref([]); //-- select DB logic --//

  const updateSelectedDatabase = async databaseId => {
    const matchedDatabase = options.value.databases.find(db => db.id === databaseId);

    if (matchedDatabase) {
      localDatabaseInfo.value.database = { ...matchedDatabase
      };
      await loadTables();
      await loadColumns();

      if (localDatabaseInfo.value.table) {
        populateColumns();
        await handleVariables();
      }
    }
  };

  const updateSelectedTable = async tableName => {
    const matchedTable = options.value.tables.find(table => table.TABLE_NAME === tableName);

    if (matchedTable) {
      localDatabaseInfo.value.table = { ...matchedTable
      };
      populateColumns();
      await handleVariables();
    }
  }; //-- variables logic --//


  const createVariables = async () => {
    try {
      const {
        appId,
        moduleId
      } = route.params;
      const columns = (localDatabaseInfo.value.action === 'Join' ? joinedTableColumns.value : selectedTableColumns.value) || [];
      let variables = columns.map(column => {
        let name = '';
        let reference = '';

        if (localDatabaseInfo.value.action === 'Join') {
          name = `${localDatabaseInfo.value.database.name} > ${column.table_label} > ${column.column_label}`;
          reference = `${moduleGraphStore.selectedNodeId}-${column.table_name}-${column.column_name}`;
        } else {
          name = `${localDatabaseInfo.value.database.name} > ${column.column_label}`;
          reference = `${moduleGraphStore.selectedNodeId}-${column.column_name}`;
        }

        return {
          name,
          reference
        };
      });
      await moduleStore.createVariable(appId, moduleId, {
        module_id: moduleId,
        node_id: moduleGraphStore.selectedNodeId,
        node_name: localDatabaseInfo.value.name,
        variables
      });
    } catch (err) {
      console.error(err);
    }
  };

  const deleteStaleVariables = async () => {
    try {
      const {
        appId,
        moduleId
      } = route.params;
      const columns = (localDatabaseInfo.value.action === 'Join' ? joinedTableColumns.value : selectedTableColumns.value) || [];
      const columnNames = columns.map(column => column.column_name);
      const references = moduleStore.variables.filter(variable => {
        const columnName = variable.name.split('>').pop();
        return variable.node_id === moduleGraphStore.selectedNodeId && !columnNames.includes(columnName);
      }).map(variable => variable.reference);
      await moduleStore.deleteVariables(appId, moduleId, references);
    } catch (err) {
      console.error(err);
    }
  };

  const handleVariables = async () => {
    await deleteStaleVariables();

    if (['Read', 'Join'].includes(localDatabaseInfo.value.action)) {
      await createVariables();
    }
  }; //-- load info logic --//


  const loadDatabases = async () => {
    try {
      const {
        appId
      } = route.params;
      const {
        data: {
          data
        }
      } = await fetchDatabasesService({
        application_id: appId
      });
      options.value.databases = data;
    } catch (err) {
      console.error(err);
    }
  };

  const loadTables = async () => {
    try {
      const {
        data: {
          data
        }
      } = await fetchTablesService(localDatabaseInfo.value.database.id);
      options.value.tables = data;
    } catch (err) {
      console.error(err);
    }
  };

  const loadColumns = async () => {
    try {
      const columns = await Promise.all(options.value.tables.map(async table => {
        const {
          data: {
            data: columns
          }
        } = await getColumnsService(localDatabaseInfo.value.database.id, table.TABLE_NAME);
        return {
          table,
          columns
        };
      }));
      options.value.columns = columns.reduce((result, currentVal) => {
        result[currentVal.table.TABLE_NAME] = currentVal;
        return result;
      }, {});
    } catch (err) {
      console.error(err);
    }
  };

  const populateColumns = () => {
    const existingColumn = lodash.cloneDeep(localDatabaseInfo.value.columns);
    columnList.value = lodash.cloneDeep(selectedTableColumns.value);
    localDatabaseInfo.value.columns = selectedTableColumns.value.filter(column => !['id'].includes(column.column_name)).filter(column => !isDefaultDateTypeColumn(column)).map(column => {
      return {
        id: column.column_name,
        label: column.column_label,
        value: existingColumn.find(v => v.id === column.column_name)?.value || '',
        isHtml: existingColumn.find(v => v.id === column.column_name)?.isHtml || '',
        type: column.column_type
      };
    });
  };

  const handleActionUpdate = () => {
    handleVariables();
  };

  watch(() => isSidebarInfoLoaded.value, async () => {
    if (isSidebarInfoLoaded.value) {
      await loadDatabases();

      if (localDatabaseInfo.value.database) {
        await loadTables(true);
        await nextTick();

        if (localDatabaseInfo.value.action) {
          await loadColumns();
          populateColumns();
        }
      }
    }
  });
  return {
    localDatabaseInfo,
    options,
    columnList,
    updateSelectedDatabase,
    updateSelectedTable,
    handleVariables,
    handleActionUpdate
  };
};

__sfc_main.components = Object.assign({
  BaseInput,
  BaseSelect,
  BaseLabel,
  Editor,
  JoinBuilder,
  ConditionBuilder
}, __sfc_main.components);
export default __sfc_main;
</script>
